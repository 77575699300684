import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { BonificacionesService } from '@services/bonificaciones/bonificaciones.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.mode';
import { DatePipe } from '@angular/common';
import { MovimientosService } from '@services/movimientos/movimientos.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogCreateMovimientoComponent } from '../dialog-create-movimiento/dialog-create-movimiento.component';
import { AccionesDialog, TiposBonificaciones, TiposMonedas, TiposMovimiento } from '@models/base/identificadores.model';
import { isFalsy } from 'utility-types';
import { UtilidadesController } from 'src/app/controllers/utilidades.controller';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';
import { MatSelectChange } from '@angular/material/select';
import { CardContratoComponent } from '@views/contratos/card-contrato/card-contrato.component';
import { DialogCardContratoComponent } from '@views/contratos/dialog-card-contrato/dialog-card-contrato.component';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'MM/YYYY',
//   },
//   display: {
//     dateInput: 'MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@Component({
  selector: 'app-create-movimiento',
  templateUrl: './create-movimiento.component.html',
  styleUrls: ['./create-movimiento.component.css'],
  providers: [
    [DatePipe],
  ],
})
export class CreateMovimientoComponent implements OnInit {
  @Input() contratoDetailDto!: ContratoDetailDto
  esMovimientoContrato: boolean = false
  lstTiposMovimiento!: TipoMovimientoDto[];
  lstBonificaciones!: BonificacionDto[];
  movimientoForm!: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  propiedad!: BaseDto | null;
  inquilinoDto!: InquilinoDto | null;
  propietario!: PersonaPropietarioDto | null;
  classContrato = "full-width-x100"
  lstBonificacion!: BonificacionDto[];
  showBonificaciones: boolean = false
  esContratoRequerido: boolean = true;
  loading: boolean = false;
  get f() { return this.movimientoForm.controls; }
  step = 0;
  selectedTipoMovimiento: TipoMovimientoDto = new TipoMovimientoDto
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogCreateMovimientoComponent>,
    private bonificacionService: BonificacionesService,
    private tiposMoviemientoService: TiposMoviemientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private movimientosService: MovimientosService,
    private contratoService: ContratosService,
    private bonificacionesService: BonificacionesService,
    private cdr: ChangeDetectorRef

  ) {
    const today = new Date();
    this.movimientoForm = this.formBuilder.group({
      TipoMovimiento: ["", Validators.required],
      Importe: ["", Validators.required],
      Contrato: ["", Validators.required],
      FechaEmision: [today, Validators.required],
      Bonificaciones: [""],
      Inquilino: [""],
      IsSelectedCheckInquilino: [false],
      IsSelectedCheckPropiedad: [false],
      IsSelectedCheckPropietario: [false],
      Propietario: [""],
      Propiedad: [""],
      Descripcion: [""],
    })
  }

  ngOnInit(): void {
    this.getAllTiposMovimientos()
    if (!isFalsy(this.contratoDetailDto))
    {
      this.getContrato(this.contratoDetailDto.id)
      this.esMovimientoContrato = true
    }


  }


  onSubmit() {
    let createMovimientoRequest = this.setCreateMovimientoRequest()
    if (createMovimientoRequest) {
      if (createMovimientoRequest.idBonificacion != null && createMovimientoRequest.idBonificacion != undefined)
        this.createMovimientosConBonificacion(createMovimientoRequest)
      else
        this.createMovimiento(createMovimientoRequest)
    }
  }

  setCreateMovimientoRequest(): CreateMovimientoRequest {
    let tipoMovimientoId = this.movimientoForm.controls["TipoMovimiento"].value
    let descripcion = this.movimientoForm.controls["Descripcion"].value == "" ? null : this.movimientoForm.controls["Descripcion"].value
    let importe = this.movimientoForm.controls["Importe"].value == "" ? null : parseFloat(this.movimientoForm.controls["Importe"].value)
    let periodoAnio = this.datepipe.transform(this.movimientoForm.controls["FechaEmision"].value, 'yyyy') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["FechaEmision"].value, 'yyyy')
    let periodoMes = this.datepipe.transform(this.movimientoForm.controls["FechaEmision"].value, 'MM') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["FechaEmision"].value, 'MM')
    let periodo = 0
    let contratoId = isFalsy(this.contratoDetailDto) ? null : this.contratoDetailDto.id
    let propiedadId = isFalsy(this.propiedad) ? null : this.propiedad.id
    let inquilinoId = isFalsy(this.inquilinoDto) ? null : this.inquilinoDto.id
    let propietarioId = isFalsy(this.propietario) ? null : this.propietario.id_Propietario
    let fechaEmision = this.datepipe.transform(this.movimientoForm.controls["FechaEmision"].value, 'yyyy-MM-dd') as string;
    let bonificacion = this.movimientoForm.controls["Bonificaciones"].value == "" ? null : parseFloat(this.movimientoForm.controls["Bonificaciones"].value.id)

    let createMovimientoRequest: CreateMovimientoRequest = new CreateMovimientoRequest
    createMovimientoRequest.descripcion = descripcion
    createMovimientoRequest.idTipoMovimiento = tipoMovimientoId
    createMovimientoRequest.importe = importe
    createMovimientoRequest.periodo = periodo
    createMovimientoRequest.periodoAnio = isFalsy(periodoAnio) ? null : parseInt(periodoAnio)
    createMovimientoRequest.periodoMes = isFalsy(periodoMes) ? null : parseInt(periodoMes)
    createMovimientoRequest.idContrato = contratoId
    if (this.movimientoForm.controls["IsSelectedCheckPropiedad"].value)
      createMovimientoRequest.idPropiedad = propiedadId
    if (this.movimientoForm.controls["IsSelectedCheckInquilino"].value)
      createMovimientoRequest.idInquilino = inquilinoId
    if (this.movimientoForm.controls["IsSelectedCheckPropietario"].value)
      createMovimientoRequest.idPropietario = propietarioId
    createMovimientoRequest.fechaEmision = fechaEmision
    createMovimientoRequest.fechaPago = null
    createMovimientoRequest.idBonificacion = bonificacion
    createMovimientoRequest.idMovimientoRef = null
    createMovimientoRequest.idPeriodoContrato = null
    createMovimientoRequest.idTipoMoneda = TiposMonedas.Pesos

    return createMovimientoRequest

  }
  createMovimiento(createMovimientoRequest: CreateMovimientoRequest) {
    this.spinner.show("spMovimiento")
    this.movimientosService.create(createMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showSuccess("Movimiento creado correctamente.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  createMovimientosConBonificacion(createMovimientoRequest: CreateMovimientoRequest) {
    this.spinner.show("spMovimiento")
    this.movimientosService.createMovimientosConBonificacion(createMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showSuccess("Movimiento creado correctamente.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  createCollectionMovimiento(createCollectionMovimientoRequest: CreateMovimientoRequest[]) {
    this.spinner.show("spMovimiento")
    this.movimientosService.createCollection(createCollectionMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showSuccess("Movimientos creados correctamente.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  //#region Servicios

  getAllTiposMovimientos() {
    this.spinner.show("spBusquedaTiposMovimiento")
    this.tiposMoviemientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.lstTiposMovimiento = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getContrato(id: number) {
    this.loading = true;
    this.spinner.show("spBusquedaContrato")
    this.contratoService.getById(id)
      .subscribe(
        async data => {
          this.loading = false;
          this.spinner.hide("spBusquedaContrato")
          this.contratoDetailDto = data
          this.inquilinoDto = this.contratoDetailDto.inquilino
          let personaPropietarioDto = new PersonaPropietarioDto
          personaPropietarioDto.id_Propietario = this.contratoDetailDto.propietarios[0].id
          personaPropietarioDto.id = this.contratoDetailDto.propietarios[0].persona.id
          personaPropietarioDto.descripcion = this.contratoDetailDto.propietarios[0].persona.descripcion
          this.propietario = personaPropietarioDto

          let propiedad = new BaseDto
          propiedad.id = this.contratoDetailDto.propiedad.id
          propiedad.descripcion = (isFalsy(this.contratoDetailDto.propiedad.ubicacion) ? '' : this.contratoDetailDto.propiedad.ubicacion) +
            (isFalsy(this.contratoDetailDto.propiedad.piso) ? '' : ' Piso: ' + this.contratoDetailDto.propiedad.piso.toUpperCase()) +
            (isFalsy(this.contratoDetailDto.propiedad.departamento) ? '' : ' Depto.: ' + this.contratoDetailDto.propiedad.departamento.toUpperCase())

          this.propiedad = propiedad
          this.movimientoForm.controls["Contrato"].setValue(this.contratoDetailDto)
        },
        error => {
          this.loading = false;
          this.spinner.hide("spBusquedaContrato")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllBonificacion() {
    this.spinner.show("spBusquedaTiposBonificaciones")
    this.bonificacionService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposBonificaciones")
          this.lstBonificaciones = data
        },
        error => {
          this.spinner.hide("spBusquedaTiposBonificaciones")
          this.snackBar.showError(error, "Error");
        })
  }


  onTipoMovimientoChange(event: any) {
    if (event == TiposMovimiento.Bonificaciones) {
      this.getAllBonificacion()
      this.showBonificaciones = true
    }
  }

  // Método que verifica si al menos uno de los tres controles está seleccionado
  isAnySelected(): boolean {
    var IsSelectedCheckInquilino = this.movimientoForm.controls["IsSelectedCheckInquilino"].value
    var IsSelectedCheckPropietario = this.movimientoForm.controls["IsSelectedCheckPropietario"].value
    var IsSelectedCheckPropiedad = this.movimientoForm.controls["IsSelectedCheckPropiedad"].value
    return IsSelectedCheckInquilino || IsSelectedCheckPropietario || IsSelectedCheckPropiedad;
  }


  clearSelectedCheck() {
    this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(false);
    this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(false);
    this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(false);

    // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(null);
    // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(null);
    // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(null);

    // this.movimientoForm.controls["IsSelectedCheckInquilino"].clearValidators();
    // this.movimientoForm.controls["IsSelectedCheckPropiedad"].clearValidators();
    // this.movimientoForm.controls["IsSelectedCheckPropietario"].clearValidators();

    // this.movimientoForm.controls["IsSelectedCheckInquilino"].updateValueAndValidity();
    // this.movimientoForm.controls["IsSelectedCheckPropiedad"].updateValueAndValidity();
    // this.movimientoForm.controls["IsSelectedCheckPropietario"].updateValueAndValidity();


  }

  onTipoBonificacionChange(event: any) {
    switch (event.tipoBonificacion.id) {
      case TiposBonificaciones.Propietarios:
        this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(true);
        this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(true);
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(Validators.required);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].setValidators(Validators.required);

        this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(false);
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValidators(null);;
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].clearValidators();
        break;

      case TiposBonificaciones.Inmobiliaria:
        this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(true);
        this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(true);

        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValidators(Validators.required);
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(Validators.required);

        this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(false);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].setValidators(null);;
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].clearValidators();
        break;
      case TiposBonificaciones.Inquilinos:
        this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(true);
        this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(true);
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValidators(Validators.required);
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(Validators.required);

        this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(false);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].setValidators(null);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].clearValidators();
        break;
      case TiposBonificaciones.InmobiliariaInquilino:
        this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(true);
        this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(true);
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValidators(Validators.required);
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(Validators.required);

        this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(false);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].setValidators(null);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].clearValidators();
        break;
      case TiposBonificaciones.InmobiliariaPropietario:
        this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(true);
        this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(true);
        this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(true);
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValidators(Validators.required);
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(Validators.required);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].setValidators(Validators.required);
        break;

      default:
        this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValue(false);
        this.movimientoForm.controls["IsSelectedCheckInquilino"].setValue(false);
        this.movimientoForm.controls["IsSelectedCheckPropietario"].setValue(false);
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].setValidators(null);
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].setValidators(null);
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].setValidators(null);
        // this.movimientoForm.controls["IsSelectedCheckPropiedad"].clearValidators();
        // this.movimientoForm.controls["IsSelectedCheckInquilino"].clearValidators();
        // this.movimientoForm.controls["IsSelectedCheckPropietario"].clearValidators();
        break;


    }
    this.cdr.detectChanges(); // Forza la detección de cambios
  }


  // Método para validar el campo "Importe"
  validarImporteYTipoMovimiento() {
    const tipoMovimiento = this.movimientoForm.get('TipoMovimiento')?.value;
    const importe = this.movimientoForm.get('Importe')?.value;
    if (importe && importe >= 0 && tipoMovimiento && tipoMovimiento >= 0) {
      return true
    } else {
      return false
    }
  }

  verContrato() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = "95%"
    dialogConfig.data = this.contratoDetailDto

    let dialogRef = this.dialog.open(DialogCardContratoComponent, dialogConfig);

  }

  selectPropiedad(event: BaseDto | null) {
    if (event == null) {
      this.propiedad = null
      this.movimientoForm.controls["Propiedad"].setValue(null)
    }
    else {
      this.propiedad = event
      this.movimientoForm.controls["Propiedad"].setValue(this.propiedad)
    }
  }

  selectContrato(baseDto: BaseDto) {

    this.getContrato(baseDto.id)
    this.movimientoForm.controls["Contrato"].setValue(this.getContrato(baseDto.id))
  }

  selectInquilino(event: InquilinoDto | null) {
    if (event == null) {
      this.inquilinoDto = null
      this.movimientoForm.controls["Inquilino"].setValue(null)
    }
    else {
      this.inquilinoDto = event
      this.movimientoForm.controls["Inquilino"].setValue(this.inquilinoDto)
    }

  }

  selectPropietario(event: PersonaPropietarioDto | null) {
    if (event == null) {
      this.propietario = null
      this.movimientoForm.controls["Propietario"].setValue(null)
    }
    else {
      this.propietario = event
      this.movimientoForm.controls["Propietario"].setValue(this.propietario)
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.year(normalizedYear.year());
    // this.date.setValue(ctrlValue);
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.month(normalizedMonth.month());
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
    datepicker.close();
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  trackByItems(index: number, item: any): any { return item; }

}


//#endregion


